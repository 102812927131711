import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import api from './api'
import Dayjs from 'vue-dayjs';
import filters from './filters'

Vue.config.productionTip = false
Vue.prototype.$api = api

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use(Dayjs, {});

// settings for vue-html-to-paper
const paperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(paperOptions);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
