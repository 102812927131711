<template lang="pug">
v-app
  router-view
  notification
</template>

<script>
import notification from '@/components/common/notification'
export default {
  name: 'App',
  components: {
    notification
  },
  data: () => ({})
};
</script>
