<template lang="pug">
v-main(class="login-container")
  v-container
    v-img(
      :src="require('@/assets/logo.png')"
      class="my-4"
      contain
      height="50"
    )
    router-view
</template>

<script>


export default {
  name: 'Login',
}
</script>
<style scoped>
.login-container {
  height: 100vh;
  background: #1c3f4c;
}
</style>
