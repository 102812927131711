import router from "../router";

export const toLogin = () => {
  router
    .replace({
      name: "Login",
    })
    .catch((err) => {
      err;
    });
};

export const toMainPage = () => {
  router
    .replace({
      name: "RegistrationSearch",
    })
    .catch((err) => {
      err;
    });
};

export const dev =
  "https://eudvtcxmoicrbscuuwivaevasadzxrsvfinancialdata.changelifedev.com";
export const prod =
  "https://9466126923939899396486034789145financialdata.changelifeapp.com";
