import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import notification from './modules/notification'
import group from './modules/group'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    notification,
    group
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
      reducer(val) {
        return {
          auth: val.auth
        }
      }
  })]
})
