const group = {
  namespaced: true,
  state: {
    groupType: [],
    district: [],
    group: [],
    clcList: []
  },
  mutations: {
    SET_GROUP (state, options) {
      state.groupType = options.groupType,
      state.district = options.district,
      state.group = options.group
    },
    SET_CLC_LIST (state, options) {
      state.clcList = options
    }
  },
  actions: {
    setGroup (context, options) {
      context.commit('SET_GROUP', {
        groupType: options.group_type,
        district: options.district,
        group: options.group
      })
    },
    setClcList (context, options) {
      context.commit('SET_CLC_LIST', {
        clcList: options
      })
    }
  }
}
export default group