<template lang="pug">
v-row(no-gutter)
  v-col(cols="12" md="4" class="px-1")
    select(
      v-model="groupType" 
      class="edit-group"
    )
      option(
        v-for="(item, groupTypeIndex) in groupTypeOptions"
        :key="groupTypeIndex"
        :value="item"
      ) {{ item }}
  v-col(cols="12" md="4" class="px-1")
    select(
      v-model="districtName" 
      class="edit-group"
    )
      option(
        v-for="(item, districtIndex) in districtOptions"
        :key="districtIndex"
        :value="item"
      ) {{ item }}
  v-col(cols="12" md="4" class="px-1")
    select(
      v-model="groupId" 
      class="edit-group"
    )
      option(
        v-for="(item, groupIndex) in groupOptions"
        :key="groupIndex"
        :value="item.groupId"
      ) {{ item.groupName }}
</template>

<script>
export default {
  name: 'Group',
  props: {
    value: {
      type: String
    }
  },
  data: () => ({
    groupType: '',
    districtName: '',
    groupId: '',
  }),
  watch: {
    groupId(val) {
      this.$emit('groupId', val)
    }
  },
  computed: {
    groupTypeOptions () {
      return this.$store.state.group.groupType
    },
    groups () {
      return this.$store.state.group.group
    },
    districtOptions () {
      const groupMap = this.groups
        .filter(group => group.group_type_name === this.groupType)
        .reduce((acc, g) => {
          acc[g.district_name] = true
          return acc
        }, {})
      return Object.keys(groupMap).map(g => {return g})
    },
    groupOptions () {
      return this.groups
        .filter(g => g.district_name === this.districtName)
        .map(g => ({ groupName: g.group_name, groupId: g.group_id }))
    },
  },
  created () {
    this.groupId = this.value
    let selectedGroup = this.groups.find((item) => { return item.group_id === this.value })
    this.groupType = selectedGroup ? selectedGroup.group_type_name : ''
    this.districtName = selectedGroup ? selectedGroup.district_name : ''
  }   
}
</script>
<style scoped>
.edit-group {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  width: 100%;
}
</style>