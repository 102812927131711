<template lang="pug">
div
  v-snackbar(
    :color="this.$store.state.notification.color"
    top right v-model="visible")
    span {{ this.$store.state.notification.msg }}
    v-btn(
      v-if="showClose"
      @click="close"
      text
    ) x
</template>

<script>
export default {
  name: 'Notification',
  data: () => ({}),
  computed: {
    visible () {
      return this.$store.state.notification.visible
    },
    showClose () {
      return this.$store.state.notification.showClose
    }
  },
  methods: {
    close () {
      this.$store.commit('notification/CLOSE_NOTIFICATION')
    }
  }
}
</script>
