<template lang="pug">
v-container(fluid)
  div(class="d-flex justify-space-between mb-2")
    h2(class="blue-grey--text")
      v-icon(class="mr-1") mdi-progress-wrench
      span 開發中
  div(class="d-flex")
    img(src="@/assets/work-in-progress.png" class="mx-auto")
</template>

<script>
export default {
  name: "progressing",
};
</script>
