<template lang="pug">
v-card(
  class="mx-auto pa-4"
  max-width="400"
)
  form(
    @submit.prevent="submit")
    v-text-field(
      v-model="loginData.email"
      label="Email"
      required
    )
    v-text-field(
      v-model="loginData.phone"
      label="手機"
      required
    )
    v-text-field(
      v-model="loginData.password"
      label="密碼"
      type="password"
      required
    )
    v-btn(
      class="mr-4"
      type="submit"
      color="primary"
      block
    ) 登入
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      loginData: {
        email: '',
        phone: '',
        password: ''
      }
    }
  },
  methods: {
    async submit () {
      this.$emit('login', this.loginData)
    }
  }
}
</script>
