<template lang="pug">
v-container(fluid)
  div(class="d-flex justify-space-between mb-2")
    h2(class="blue-grey--text")
      v-icon(class="mr-1") mdi-format-list-numbered
      span 奉獻編號管理
  SearchForm(
    :addMode="addMode"
    @add="handleAdd"
    @search="handleSearch"
  )
  v-divider(class="my-4")
  SearchTable(
    :loading="loading"
    :dataList="listData"
    :addMode="addMode"
    @update="handleUpdate"
    @change="handleChange"
    @addItem="handleAddItem"
    @cancelAddItem="handleCancelAddItem"
  )
</template>

<script>
import SearchForm from "@/components/dedicationNumber/searchForm";
import SearchTable from "@/components/dedicationNumber/searchTable";
export default {
  name: "dedicationNumberSearch",
  components: {
    SearchForm,
    SearchTable,
  },
  data: () => ({
    analyzeData: {},
    group: [],
    listData: [],
    loading: false,
    addMode: false,
  }),
  methods: {
    async getClcList() {
      try {
        let clcListRes = await this.$api.common.commonAPI.getClcList();
        if (clcListRes && clcListRes.data.code === 200) {
          this.$store.dispatch("group/setClcList", clcListRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getClcGroupSelect() {
      try {
        let groupRes = await this.$api.common.commonAPI.getClcGroupList();
        if (groupRes && groupRes.data.code === 200) {
          this.$store.dispatch("group/setGroup", groupRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getList(payload) {
      try {
        this.loading = true;
        let listRes =
          await this.$api.dedicationNumber.dedicationNumberAPI.getList(payload);
        if (listRes && listRes.data.code === 200) {
          this.listData = listRes.data.content;
        }
        if (!listRes.data.success && listRes.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: listRes.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
          if (listRes.data.isRedirect) {
            this.$router.push("/permissions");
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    handleSearch(searchPayload) {
      this.getList(searchPayload);
    },

    async handleUpdate(item) {
      try {
        let res =
          await this.$api.dedicationNumber.dedicationNumberAPI.modifyDedicationNumber(
            item
          );
        if (res && res.data.code === 200) {
          this.$store.dispatch("notification/openNotification", {
            msg: `${item.name} 資料已更新`,
            color: "green",
            timeout: 5000,
            showClose: false,
          });
        }
        if (!res.data.success && res.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: res.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.getList({});
      }
    },

    async handleChange(item) {
      try {
        let res =
          await this.$api.dedicationNumber.dedicationNumberAPI.changeDedicationNumber(
            item
          );
        if (res && res.data.code === 200) {
          this.$store.dispatch("notification/openNotification", {
            msg: `使用者已變更`,
            color: "green",
            timeout: 5000,
            showClose: false,
          });
        }
        if (!res.data.success && res.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: res.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.getList({});
      }
    },

    handleAdd() {
      this.listData.unshift({
        parmChurch: null,
        number: null,
        memberId: "",
        groupId: null,
        name: null,
        lastDedicationDate: this.$dayjs().format("YYYY-MM-DD"),
        isExpired: 0,
        statusText: "使用中",
        updateMemberId: "",
        updateMemberText: "",
      });
      this.addMode = true;
    },

    handleCancelAddItem() {
      this.listData = this.listData.filter((item) => {
        return item.id;
      });
      this.addMode = false;
    },

    async handleAddItem(item) {
      try {
        let res =
          await this.$api.dedicationNumber.dedicationNumberAPI.addDedicationNumber(
            item
          );

        if (res && res.data.code === 200) {
          this.$store.dispatch("notification/openNotification", {
            msg: `${item.name} 資料已新增`,
            color: "green",
            timeout: 5000,
            showClose: false,
          });
        }
        if (!res.data.success && res.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: res.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.addMode = false;
        this.getList({});
      }
    },
  },
  created() {
    this.getClcList();
    this.getClcGroupSelect();
    // this.getList({});
  },
};
</script>
