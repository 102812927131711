const notification = {
  namespaced: true,
  state: {
    msg: '',
    visible: false,
    showClose: true,
    timeout: 0,
    color: ''
  },
  mutations: {
    OPEN_NOTIFICATION (state, options) {
      state.visible = true
      state.msg = options.msg
      state.color = options.color
      state.showClose = options.showClose
    },
    CLOSE_NOTIFICATION (state) {
      state.visible = false
    }
  },
  actions: {
    openNotification (context, options) {
      context.commit('OPEN_NOTIFICATION', {
        msg: options.msg,
        color: options.color,
        showClose: options.showClose
      })
      setTimeout(() => {
        context.commit('CLOSE_NOTIFICATION')
      }, options.timeout)
    }
  }
}
export default notification